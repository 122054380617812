import React, { useContext, useEffect } from "react";
import { Button, MainTitle, Section } from "../components/Core";
import GlobalContext from './../context/GlobalContext';
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { Title, Box, Text } from "../components/Core";
import { device } from "../utils";
import { Col, Container, Row } from "react-bootstrap";
import CheckAvailability from "../sections/index/CheckAvailability";
import Seo from "../components/Seo/Seo";
import scrollTo from 'gatsby-plugin-smoothscroll';

import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";

import notInProjectArea from './../assets/image/png/not-in-project-area.png';
import propertEligibility from './../assets/image/png/property-eligibility.png';
import gbvs from './../assets/image/png/gbvs.png';

import step1 from './../assets/image/png/step-1.png';
import step2 from './../assets/image/png/step-2.png';
import step3 from './../assets/image/png/step-3.png';
import step4 from './../assets/image/png/step-4.png';
import step5 from './../assets/image/png/step-5.png';
import step6 from './../assets/image/png/step-6.png';
import step7 from './../assets/image/png/step-7.png';

import checkmark from './../assets/image/png/checkmark.png';
import bdukLeftMotive from './../assets/image/png/bduk-left-motive.png';
import bdukBottomMotive from './../assets/image/png/left-motive-bottom.png';
import pinkRightMotive from './../assets/image/png/pink-right-motive.png';
import howToGetInvolved from './../assets/image/png/how-to-get-involved.png';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import howTo1 from './../assets/image/svg/how-to-get-involved-1.svg';
import howTo2 from './../assets/image/svg/how-to-get-involved-2.svg';
import howTo3 from './../assets/image/svg/how-to-get-involved-3.svg';

const gfm = require('remark-gfm')

const BoxStyled = styled(Box)`
    box-shadow: rgba(65, 62, 101, 0.176) 0px 20px 61px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    margin-top: 100px;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;

const BigTitle = styled(MainTitle)`{
  font-size: 2.8em;
  line-height: 1.0;
}`

const NormalTitle = styled(Title)`{
  font-size: 2.3em;
}`

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
  width: 100%;
`;

const SliderText = styled(Text)`
  font-size: 18px;
  line-height: 28px;
  max-width: 350px;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const SectionBackground = styled(Section)`
  @media (max-width: 1200px) {
    background: none !important;
  }

  @media (min-width: 1200px) and (max-width: 1600px) { 
    background-size: 28% !important;
  }
`;

const SectionBackgroundHalf = styled(SectionBackground)`
    @media (min-width: 1200px) and (max-width: 2000px) { 
        background-size: 50% !important;
    }
`;

const SectionBackgroundFull = styled(Section)`
    @media (min-width: 1200px) and (max-width: 1600px) { 
        background-size: 100% !important;
    }
`;

const GBVS = () => {

    const gContext = useContext(GlobalContext);
    const registrationData = gContext.goGetRegistrationData();

    const responsive = {
        0: { items: 1 }
    };

    const data = useStaticQuery(graphql`
        query gbvsQuery {
            strapiVoucherPage {
                subtitle_text_1
                subtitle_text_2
                subtitle_1
                subtitle_1_text
                subtitle_2
                steps_notice_text
                subtitle_2_text
                steps_title
                step_1_text
                step_2_text
                step_3_text
                step_4_text
                step_5_text
                step_6_text
            }
            strapiPageHeadings(slug: {eq: "gigabit-voucher-scheme"}) {
                h1
                h21
                h22
                h23
            }
        }`
    );

    const pageData = getProperty(data, 'strapiVoucherPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    const onDetailsClick = () => {
        if (typeof window !== 'undefined') {
            window.open('https://www.gov.uk/government/publications/gigabit-broadband-voucher-scheme-information/gigabit-broadband-voucher-scheme-information', '_blank');
        }
    }

    return (
        <div>
            <Seo page="gbvs" />
            <PageWrapper footerDark>
                <Section style={{ background: '#fbf5f9' }} className="pb-0">
                    <SectionBackground style={{ background: `url(${pinkRightMotive})`, backgroundPosition: '100% 0', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', paddingBottom: 0 }}>
                        <Container>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <BigTitle className="mb-5">
                                        The Gigabit<br /> Broadband<br /> Voucher Scheme
                                    </BigTitle>
                                </Col>
                            </Row>
                        </Container>
                    </SectionBackground>
                    <SectionBackground className="pb-0" style={{ background: `url(${bdukLeftMotive})`, backgroundPosition: '0 100% ', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', paddingBottom: 0, paddingTop: 0 }}>
                        <Container>
                            <Row>
                                <Col xs={12} xl={9} className="offset-xl-3">
                                    <Row>
                                        <Col xs={12} xl={10}>
                                            <Row className="align-items-center">
                                                <Col xs={12} md={6} className="mb-3">
                                                    <img style={{ 'box-shadow': '0 0 8px 1px rgba(0, 0, 0, 0.1)', padding: 16, borderRadius: 25 }} src={gbvs} width={250} />
                                                </Col>
                                                <Col xs={12} md={6} className="mb-3">
                                                    <Button
                                                        style={{
                                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                                            border: 'none',
                                                            width: 'auto'
                                                        }}
                                                        className="btn-red"
                                                        onClick={() =>scrollTo('#check-availability')}
                                                    >
                                                        Register now
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Text>
                                                The Gigabit Broadband Voucher Scheme is a part of the <a href="https://www.gov.uk/government/news/government-launches-new-5bn-project-gigabit" target="_blank">
                                                Government’s Project Gigabit programme</a> which is aiming to connect businesses and residents in some of the rural and
                                                urban connectivity not-spots in the UK to gigabit-capable broadband.
                                            </Text>
                                            <br />
                                            <Text className="pb-5">
                                                The voucher scheme has been set up to help provide
                                                additional funding towards the cost of installing
                                                gigabit-capable broadband to your premises when part
                                                of a group project (minimum of 2 premises).
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </SectionBackground>

                </Section>
                <Section>
                    <Container>
                        <Title className="pink-color">Property Eligibility</Title>
                        <Text className="mb-5">
                            All eligible properties (both business and residential) can receive up to £4,500
                            worth of funding. Individual vouchers are collected to subsidise a proportion of
                            the costs required to build the network and install it directly to your property.
                        </Text>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Text className="mt-3 mb-3">
                                    <strong>
                                        To obtain a voucher your property must meet the
                                        following eligibility requirements:
                                    </strong>
                                </Text>
                                <ul>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />Must receive broadband speeds less than 100Mbps</li>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />The new connection must double the existing connection speed</li>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />Must be a part of a group project - 2 or more properties</li>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />Must take a minimum 12-month service with an Internet Service Provider (ISP) that is on Netomnia’s network.</li>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />Must not be in any public subsidy plans in the next 3 years.</li>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />Not have received a Gigabit broadband voucher already</li>
                                    <li className="flex mb-1"><img src={checkmark} width={20} />Only one voucher per household or business</li>
                                </ul>
                                <Button
                                    className="mt-5 mb-3"
                                    onClick={() => onDetailsClick()}
                                >
                                    Details of the scheme<br />
                                    can be found here.
                                </Button>
                            </Col>
                            <Col xs={12} lg={6}>
                                <img src={propertEligibility} className="w-100" />
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <SectionBackgroundFull style={{ background: `url(${howToGetInvolved})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%' }} id="check-availability">
                    <Container>
                        <Row>
                            <Col sm={12} lg={6} className="offset-lg-3">
                                <Text className="text-center">To check your eligibility, enter your postcode below</Text>
                                <CheckAvailability
                                    focusInput={false}
                                    hideLogo={true}
                                    customTitle={""}
                                    customSubtitle={""}
                                />
                            </Col>
                        </Row>
                        <BoxStyled>
                            <Title className="mt-5 pt-5 pb-5 text-center">How to get involved</Title>
                            <div
                                className="gbvs-page"
                            >
                                <AliceCarousel
                                    mouseTracking
                                    controlsStrategy="alternate"
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlayInterval={4000}
                                    renderPrevButton={() => {
                                        return <i className="fa fa fa-chevron-left"></i>
                                    }}
                                    renderNextButton={() => {
                                        return <i className="fa fa fa-chevron-right"></i>
                                    }}
                                >
                                    <div>
                                        <img src={howTo1} width={100} className="m-auto d-block" />
                                        <SliderText>
                                            Check your postcode in our availability checker to establish if
                                            you are in a project area. (If your property is not included, please see the information below)
                                        </SliderText>
                                    </div>
                                    <div>
                                        <img src={howTo2} width={100} className="m-auto d-block" />
                                        <SliderText>
                                            Fill out the registration & consent form and select
                                            your preferred service package. This package selection is non-committal,
                                            and you will need to re-select a package when your property goes live.
                                        </SliderText>
                                    </div>
                                    <div>
                                        <img src={howTo3} width={100} className="m-auto d-block" />
                                        <SliderText>
                                            If your property is already live, click through to one
                                            of our internet service providers and order a package.
                                            If your property is in build, keep a lookout for communication
                                            from us regarding updates on your project.
                                        </SliderText>
                                    </div>
                                </AliceCarousel>
                            </div>
                        </BoxStyled>
                    </Container>
                </SectionBackgroundFull>
                <SectionBackgroundHalf style={{ background: `url(${bdukBottomMotive})`, backgroundPosition: '0 0 ', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', paddingBottom: 0, paddingTop: 0 }}>
                    <Container>
                        <Row>
                            <Col xs={12} xl={6} className="offset-xl-6">
                                <Title className="mt-5 pink-color">How the voucher is funded</Title>
                                <ul>
                                    <li className="flex mb-3">
                                        <img src={step1} width={38} />
                                        We will pool your voucher pledge (completion of
                                        registration form) with other pledges from your
                                        local community.
                                    </li>
                                    <li className="flex mb-3">
                                        <img src={step2} width={38} />
                                        Once enough vouchers have been received, we
                                        will request funding to be allocated to your local
                                        project by DSIT
                                    </li>
                                    <li className="flex mb-3">
                                        <img src={step3} width={38} />
                                        Once requested, DSIT will contact you via email to
                                        confirm your voucher pledge. This email must be
                                        actioned within 28 days. Funding is not secured until
                                        this confirmation email is actioned.
                                    </li>
                                    <li className="flex mb-3">
                                        <img src={step4} width={38} />
                                        Once the email is actioned, the voucher to build to your
                                        property will be issued. We will build our ultrafast full fibre
                                        network to your property within 12 months.
                                    </li>
                                    <li className="flex mb-3">
                                        <img src={step5} width={38} />
                                        Once the network is live you will be contacted by Netomnia
                                        to place your order with a chosen internet service provider on our network
                                    </li>
                                    <li className="flex mb-3">
                                        <img src={step6} width={38} />
                                        Once installed you will receive a second email from DSIT asking for
                                        your confirmation that your install is complete. This must be completed
                                        or you will be at risk of being charged an installation fee.
                                    </li>
                                    <li className="flex mb-3">
                                        <img src={step7} width={38} />
                                        <div>
                                            12 months after your installation, you will receive a third email from DSIT
                                            asking for you to confirm that you still have a live gigabit capable broadband connection.
                                            They'll also ask if you've been satisfied with the service! <strong>
                                            Please respond to this email as it can impact our future gigabit voucher scheme builds.</strong>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </SectionBackgroundHalf>
                <Section style={{ background: 'linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%)', marginTop: '100px' }}>
                    <Container className="text-center">
                        <Row>
                            <Col xs={12} lg={7} className="centered">
                                <Title className="white-color">IMPORTANT</Title>
                                <Text className="white-color">
                                    To help us make this project a success for you and your community,
                                    we would like to build the network as quickly as possible; please
                                    assist us in raising awareness of this scheme in your community.
                                </Text>
                                <Text className="white-color">
                                    <strong>
                                        The project is dependent on residents and local businesses
                                        pledging their voucher to our project.
                                    </strong>
                                </Text>
                                <Text className="white-color">
                                    <strong>
                                        If sufficient vouchers are not received the project may not go
                                        ahead. If the project is unsuccessful, we will contact you to
                                        confirm we are not progressing with the project.
                                    </strong>
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section>
                    <Container>
                        <Row>
                            <Col xs={12} lg={7}>
                                <Title className="mt-5 pink-color">Not in a project area?</Title>
                                <Row>
                                    <Col xs={12} lg={10}>
                                        <Text className="mb-5">
                                            If your address is not included in a project at
                                            this time, you can register your consent for
                                            future consideration. If we establish a project in
                                            your area or bring your address into an
                                            established project, by completing our
                                            registration of consent, you agree for us to
                                            contact you in the future.
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={5}>
                                <img src={notInProjectArea} className="w-100" />
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section style={{ background: '#e5eaef' }}>
                    <Container>
                        <Row>
                            <Col sm={12} lg={6} className="offset-lg-3">
                                <Text className="text-center">To check your eligibility, enter your postcode below</Text>
                                <CheckAvailability
                                    focusInput={false}
                                    hideLogo={true}
                                    customTitle={""}
                                    customSubtitle={""}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper >
        </div >
    )
}

export default GBVS
